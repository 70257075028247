import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  LinearProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import api from 'src/services/api';
import * as yup from 'yup';
import FormPreviewDev from '../../components/common/FormPreviewDev';
import Page from '../../components/Page';
import adminService from '../../services/adminService';
import { consoleError, getImageUrl } from '../../utils/helper';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // height: 'auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  switchField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dropDown: {
    height: 400,
  },
}));
function EditTrackAward() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [tempImage, setTempImage] = useState();
  const [uploadingImage, setUploadingImage] = useState(false);
  const [uploadingError, setUploadingError] = useState('');

  const pages = useSelector((state) => state.admin.pages);
  const pageData = pages.find((Item) => Item.slug === 'track');
  useEffect(() => {
    async function getPages() {
      try {
        adminService.getPages(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getPages();
  }, []);

  useEffect(() => {
    if (pageData) {
      setUpdate(true);
    }
  }, [pageData]);

  const addSiteSettingchema = yup.object().shape({
    title: yup
      .string()
      .required('title is required.')
      .min(2, 'title has min 2 chars.')
      .max(100, 'title has max 100 chars.'),
  });
  const updateSiteSettingchema = yup.object().shape({
    title: yup
      .string()
      .required('title is required.')
      .min(2, 'title has min 2 chars.')
      .max(100, 'title has max 100 chars.'),
  });

  const uploadImageHandler = async (e, values, setFieldValue) => {
    setUploadingImage(true);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    try {
      const res = await api.post(`/api/v1/admin/file/upload/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const image_New_Name = res.data;
      // values.image = image_New_Name;
      setTempImage(image_New_Name);
      setUploadingImage(false);
      console.log(values);
    } catch (error) {
      console.error(error);
      setUploadingImage(false);
    }
  };

  const uploadImageHandlerTinyMCE = async (file) => {
    console.log(file);
    const formData = new FormData();
    formData.append('image', file);
    try {
      const res = await api.post(`/api/v1/admin/file/upload/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (res && res.data) {
        return res.data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Page className={classes.root} title={update ? 'Update page' : 'Add page'}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="lg">
          <Box my={2} display="flex" alignItems="center">
            <Typography variant="h2">
              {update ? 'Update page' : 'Add page'}
            </Typography>
          </Box>
          <Box mt={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link color="gray" to="/">
                Dashboard
              </Link>
              <Typography color="textPrimary">
                {update ? 'Update' : 'Add'}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Formik
            enableReinitialize={true}
            initialValues={
              update
                ? {
                    ...pageData,
                    currentImage: pageData ? pageData.image : '',
                  }
                : {
                    title: '',
                    description: '',
                    image: '',
                  }
            }
            validationSchema={
              update ? updateSiteSettingchema : addSiteSettingchema
            }
            onSubmit={async (values, { resetForm }) => {
              setErrMsg('');
              setSuccessMsg('');
              const { _id, title } = values;
              const image = tempImage;
              const data = {
                title,
                image,
              };
              if (update) {
                try {
                  let res = await adminService.updatePage(_id, data, dispatch);
                  if (res && res.ok) {
                    setSuccessMsg(res.message || 'Page updated successfully.');
                  }
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message || 'Error updating Page. Please try again',
                  );
                }
              } else {
                try {
                  let res = await adminService.addPage(data, dispatch);
                  if (res && res.ok) {
                    setSuccessMsg(res.message || 'Page added successfully.');
                    resetForm({});
                  }
                  //
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message || 'Error adding Page. Please try again.',
                  );
                }
              }
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Title"
                      margin="normal"
                      size="medium"
                      name="title"
                      fullWidth
                      variant="outlined"
                      disabled={update ? true : false}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  Banner Image <br />
                  <input
                    type="file"
                    name="image"
                    onChange={(e) => uploadImageHandler(e)}
                    accept="image/*"
                  />
                  {values.image || values.image ? (
                    <Avatar
                      src={
                        update && !values.imagae
                          ? getImageUrl(values.image)
                          : getImageUrl(values.image)
                      }
                      variant="square"
                      className={classes.image}
                    />
                  ) : null}
                  <br />
                  {/* {values.image || values.currentImage ? (
                    <img
                      src={values.image ? values.image : values.currentImage}
                      style={{
                        width: '100px',
                        height: '100px',
                        marginTop: '20px',
                      }}
                      alt="service_picture"
                    />
                  ) : (
                    ''
                  )} */}
                </Grid>

                <Box mt={1} mb={1}>
                  {uploadingError && (
                    <Alert
                      severity="error"
                      onClose={() => setUploadingError('')}
                    >
                      <AlertTitle>Error</AlertTitle>
                      {uploadingError}
                    </Alert>
                  )}
                  {errMsg && (
                    <Alert severity="error" onClose={() => setErrMsg('')}>
                      <AlertTitle>Error</AlertTitle>
                      {errMsg}
                    </Alert>
                  )}
                  {successMsg && (
                    <Alert severity="success" onClose={() => setSuccessMsg('')}>
                      <AlertTitle>Success</AlertTitle>
                      {successMsg}
                    </Alert>
                  )}
                </Box>
                {(isSubmitting || uploadingImage) && <LinearProgress />}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting || uploadingImage}
                    fullWidth
                    size="medium"
                    type="submit"
                    variant="contained"
                  >
                    {update ? 'Update Page' : 'Add Page'}
                  </Button>
                </Box>
                <FormPreviewDev data={values} />
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
}

export default EditTrackAward;
