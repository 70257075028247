const config = {
  dev: process.env.NODE_ENV !== 'production',
  apiUrl: process.env.REACT_APP_API_URL || 'https://fpsmedialog.com',
  socketUrl: process.env.REACT_APP_SOCKET_URL,
  imageUrl:
    process.env.REACT_APP_IMAGE_URL ||
    'https://fpsmedialog.com/api/public/images',
};

export default config;
