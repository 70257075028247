import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Box, Card, makeStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import { tableIcons } from '../../components/common/TableIcons';
import { Edit as EditIcon } from 'react-feather';
import adminService from '../../services/adminService';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const SiteSettingTable = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sitesetting = useSelector((state) => state.admin.siteSettings);
  useEffect(() => {
    async function getSetting() {
      try {
        adminService.getSiteSetting(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getSetting();
  }, []);

  const [updateSetting, setUpdateSeting] = useState(null);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Box maxWidth={'95%'}>
        <MaterialTable
          icons={tableIcons}
          columns={[
            {
              title: 'Title',
              field: 'title',
              filtering: false,
            },
            { title: 'Phone', field: 'phone', filtering: false },
            { title: 'Email', field: 'email', filtering: false },
            {
              title: 'Business Email',
              field: 'businessEmail',
              filtering: false,
            },
            {
              title: 'Business Hours',
              field: 'businesshours',
              filtering: false,
            },
          ]}
          data={sitesetting}
          onRowClick={(event, rowData) => navigate(`./view/${rowData._id}`)}
          title="Site Setting"
          actions={[
            {
              icon: EditIcon,
              tooltip: 'Edit Setting',
              onClick: (event, rowData) => {
                event.stopPropagation();
                setUpdateSeting(rowData);
                navigate(`/site-setting/edit/${rowData._id}`);
              },
            },
          ]}
          options={{
            rowStyle: {
              fontSize: '1rem',
              fontWeight: 'lighter',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serifs',
            },
            actionsColumnIndex: -1,
            filtering: true,
          }}
        />
      </Box>
    </Card>
  );
};

SiteSettingTable.propTypes = {
  className: PropTypes.string,
};

export default SiteSettingTable;
