import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Account = () => {
  const user = useSelector((state) => state.user.profile);

  const [values, setValues] = useState({
    name: '',
    email: '',
    phone: '',
  });
  const [valuesStaff, setValuesStaff] = useState({
    name: '',
    email: '',
    phone: '',
  });

  useEffect(() => {
    console.log('user => ', user);
    for (let i in user) {
      if (user[i].role === 'staff') {
        setValuesStaff({
          ...valuesStaff,
          ...user[i],
        });
      } else {
        setValues({
          ...values,
          ...user[i],
        });
      }
    }
  }, [user]);
  const classes = useStyles();

  return (
    <>
      <Page className={classes.root} title="Account">
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={4} md={4} xs={12}>
              <Profile isAccount={true} user={values} />
            </Grid>
            <Grid item lg={8} md={8} xs={12}>
              <ProfileDetails values={values} />
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={4} md={4} xs={12}>
              <Profile isAccount={false} user={valuesStaff} />
            </Grid>
            <Grid item lg={8} md={8} xs={12}>
              <ProfileDetails values={valuesStaff} />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
};

export default Account;
