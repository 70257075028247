import {
  ADMIN_ADD_USER,
  ADMIN_DELETE_USER,
  ADMIN_GET_USERS,
  ADMIN_UPDATE_USER,
  ADMIN_GET_SITE_SETTING,
  ADMIN_UPDATE_SITE_SETTING,
  ADMIN_GET_PAGE,
  ADMIN_ADD_PAGE,
  ADMIN_UPDATE_PAGE,
  ADMIN_DELETE_PAGE,
  ADMIN_GET_CONTENT,
  ADMIN_ADD_CONTENT,
  ADMIN_UPDATE_CONTENT,
  ADMIN_DELETE_CONTENT,
  ADMIN_GET_CONTACTUSALL,
  ADMIN_DELETE_CONTACTUS,
  ADMIN_GET_NEWS,
  ADMIN_ADD_NEWS,
  ADMIN_UPDATE_NEWS,
  ADMIN_DELETE_NEWS,
} from '../actions/adminActions';

const initialState = {
  users: [],
  pages: [],
  blogs: [],
  siteSettings: [],
  contactus: [],
  news: [],
  contents: [],
};

export default function adminReducer(state = initialState, action) {
  switch (action.type) {
    // USERS
    case ADMIN_GET_USERS:
      return {
        ...state,
        users: [...action.payload],
      };
    case ADMIN_ADD_USER: {
      const users = [...state.users];
      users.push(action.payload);
      return {
        ...state,
        users: users,
      };
    }
    case ADMIN_UPDATE_USER: {
      const users = [...state.users];
      let index = users.findIndex((item) => item._id === action.payload._id);
      users[index] = action.payload;
      return {
        ...state,
        users: users,
      };
    }
    case ADMIN_DELETE_USER: {
      const users = [...state.users];
      let index = users.findIndex((item) => item._id === action.payload);
      users.splice(index, 1);
      return {
        ...state,
        users: users,
      };
    }

    // SITE SETTING
    case ADMIN_GET_SITE_SETTING:
      return {
        ...state,
        siteSettings: [action.payload],
      };
    case ADMIN_UPDATE_SITE_SETTING: {
      const siteSettings = [...state.siteSettings];
      let index = siteSettings.findIndex(
        (item) => item._id === action.payload._id,
      );
      siteSettings[index] = action.payload;
      return {
        ...state,
        siteSettings: siteSettings,
      };
    }

    // PAGE
    case ADMIN_GET_PAGE:
      // console.log(action.payload);
      return {
        ...state,
        pages: [...action.payload],
      };
    case ADMIN_ADD_PAGE: {
      const pages = [...state.pages];
      pages.push(action.payload);
      return {
        ...state,
        pages: pages,
      };
    }
    case ADMIN_UPDATE_PAGE: {
      const pages = [...state.pages];
      let index = pages.findIndex((item) => item._id === action.payload._id);
      pages[index] = action.payload;
      return {
        ...state,
        pages: pages,
      };
    }
    case ADMIN_DELETE_PAGE: {
      const pages = [...state.pages];
      let index = pages.findIndex((item) => item._id === action.payload);
      pages.splice(index, 1);
      return {
        ...state,
        pages: pages,
      };
    }

    // CONTENT
    case ADMIN_GET_CONTENT:
      // console.log(action.payload);
      return {
        ...state,
        contents: [...action.payload],
      };
    case ADMIN_ADD_CONTENT: {
      const contents = [...state.contents];
      contents.push(action.payload);
      return {
        ...state,
        contents: contents,
      };
    }
    case ADMIN_UPDATE_CONTENT: {
      const contents = [...state.contents];
      let index = contents.findIndex((item) => item._id === action.payload._id);
      contents[index] = action.payload;
      return {
        ...state,
        contents: contents,
      };
    }
    case ADMIN_DELETE_CONTENT: {
      const contents = [...state.contents];
      let index = contents.findIndex((item) => item._id === action.payload);
      contents.splice(index, 1);
      return {
        ...state,
        contents: contents,
      };
    }

    //Contact us
    case ADMIN_GET_CONTACTUSALL:
      return {
        ...state,
        contactus: [...action.payload],
      };
    case ADMIN_DELETE_CONTACTUS: {
      const contactus = [...state.contactus];
      let index = contactus.findIndex((item) => item._id === action.payload);
      contactus.splice(index, 1);
      return {
        ...state,
        contactus: contactus,
      };
    }

    case ADMIN_GET_NEWS:
      return {
        ...state,
        news: [...action.payload],
      };
    case ADMIN_ADD_NEWS: {
      const news = [...state.news];
      news.push(action.payload);
      return {
        ...state,
        news: news,
      };
    }
    case ADMIN_UPDATE_NEWS: {
      const news = [...state.news];
      let index = news.findIndex((item) => item._id === action.payload._id);
      news[index] = action.payload;
      return {
        ...state,
        news: news,
      };
    }
    case ADMIN_DELETE_NEWS: {
      const news = [...state.news];
      let index = news.findIndex((item) => item._id === action.payload);
      news.splice(index, 1);
      return {
        ...state,
        news: news,
      };
    }

    default:
      return state;
  }
}
