import React, { useEffect } from 'react';
import Page from 'src/components/Page';

import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  //   CardHeader,
  Container,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import adminService from 'src/services/adminService';
import { useParams } from 'react-router';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import moment from 'moment';
import { Link } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

function Contact() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const contacts = useSelector((state) => state.admin.contactus);

  const { contactId } = useParams();

  const contact = contacts.find((Item) => Item._id.toString() === contactId);
  useEffect(() => {
    async function getContacts() {
      try {
        adminService.getContacts(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getContacts();
  }, []);
  return (
    <Page className={classes.root} title="Contacts">
      <Container maxWidth={false}>
        <Box mt={3}>
          <Typography color="textPrimary" gutterBottom variant="h2">
            Contact
          </Typography>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link color="gray" to="/">
              Dashboard
            </Link>
            <Link color="grey" to="/contactus">
              Contact Us
            </Link>
            <Typography color="textPrimary">View</Typography>
          </Breadcrumbs>
          {contact ? (
            <Box>
              <Card>
                <Box display="flex" ml={2} mt={2} alignItems="center">
                  <Typography color="textPrimary" variant="h4">
                    Details{' '}
                  </Typography>
                </Box>
                <CardContent>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Id
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {contact._id}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Name
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {contact.name}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Email
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                          >
                            {contact.email}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Phone
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                          >
                            {contact.phone}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Message
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                          >
                            {contact.message}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Date Time
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                          >
                            {moment(contact.createdAt).format(
                              'DD/MM/YYYY h:mm A',
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Box>
          ) : (
            <Box mt={5} display="flex" justifyContent="center">
              <Typography
                align="center"
                color="textSecondary"
                gutterBottom
                variant="h3"
              >
                Contact Not Available
              </Typography>
            </Box>
          )}
        </Box>
      </Container>
    </Page>
  );
}
export default Contact;
