import {
  CHANGE_AVATAR,
  GET_PROFILE,
  LOGIN_USER,
  UPDATE_PROFILE,
} from '../actions/userActions';

const initialState = {
  profile: {
    name: '',
    avatar: '',
  },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        profile: { ...action.payload },
      };
    case GET_PROFILE:
      console.log('GET_PROFILE => ', {
        ...state,
        profile: { ...action.payload },
      });
      return {
        ...state,
        profile: { ...action.payload },
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        profile: { ...action.payload },
      };
    case CHANGE_AVATAR:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
