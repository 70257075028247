import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';

import NewsTable from './NewsTable';
import Toolbar from './Toolbar';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const NewsListView = () => {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>News</title>
      </Helmet>
      <Box className={classes.root}>
        <Container maxWidth={false}>
          <Toolbar />
          <Box mt={3}>
            <NewsTable />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default NewsListView;
