import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  makeStyles,
  InputLabel,
  Breadcrumbs,
  MenuItem,
  Typography,
  Avatar,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import adminService from '../../services/adminService';
import { consoleError, getImageUrl } from '../../utils/helper';
import * as yup from 'yup';
import { TextField, Switch } from 'formik-material-ui';
import FormPreviewDev from '../../components/common/FormPreviewDev';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useMatch, useNavigate, Link } from 'react-router-dom';
import api from 'src/services/api';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { Editor } from '@tinymce/tinymce-react';
import CustomFileUpload from '../../components/CustomFileUpload';
import CustomFileUploadServer from '../../components/CustomFileUploadServer';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // height: 'auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  switchField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dropDown: {
    height: 400,
  },
}));
function EditAward() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [tempImage, setTempImage] = useState();
  const [uploadingImage, setUploadingImage] = useState(false);
  const [uploadingError, setUploadingError] = useState('');
  const [contentError, setContentError] = useState('');
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty(),
  );
  const editorRef = useRef(null);

  const pages = useSelector((state) => state.admin.pages);
  const pageData = pages.find((Item) => Item.slug === 'landing-page');
  console.log(pageData);
  useEffect(() => {
    async function getPages() {
      try {
        adminService.getPages(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getPages();
  }, []);

  useEffect(() => {
    if (pageData) {
      setUpdate(true);
    }
  }, [pageData]);

  const addSiteSettingchema = yup.object().shape({
    title: yup
      .string()
      .required('title is required.')
      .min(2, 'title has min 2 chars.')
      .max(100, 'title has max 100 chars.'),
  });
  const updateSiteSettingchema = yup.object().shape({
    title: yup
      .string()
      .required('title is required.')
      .min(2, 'title has min 2 chars.')
      .max(100, 'title has max 100 chars.'),
  });

  const uploadImageHandler = async (e, values, setFieldValue) => {
    setUploadingImage(true);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    try {
      const res = await api.post(`/api/v1/admin/file/upload/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const image_New_Name = res.data;
      // values.image = image_New_Name;
      setTempImage(image_New_Name);
      setUploadingImage(false);
      console.log(values);
    } catch (error) {
      console.error(error);
      setUploadingImage(false);
    }
  };

  const uploadImageHandlerTinyMCE = async (file) => {
    console.log(file);
    const formData = new FormData();
    formData.append('image', file);
    try {
      const res = await api.post(`/api/v1/admin/file/upload/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (res && res.data) {
        return res.data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Page className={classes.root} title={update ? 'Update page' : 'Add page'}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="lg">
          <Box my={2} display="flex" alignItems="center">
            <Typography variant="h2">
              {update ? 'Update page' : 'Add page'}
            </Typography>
          </Box>
          <Box mt={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link color="gray" to="/">
                Dashboard
              </Link>
              <Typography color="textPrimary">
                {update ? 'Update' : 'Add'}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Formik
            enableReinitialize={true}
            initialValues={
              update
                ? {
                    ...pageData,
                    currentImage: pageData ? pageData.image : '',
                  }
                : {
                    title: '',
                    description: '',
                    image: '',
                  }
            }
            validationSchema={
              update ? updateSiteSettingchema : addSiteSettingchema
            }
            onSubmit={async (values, { resetForm }) => {
              setErrMsg('');
              setSuccessMsg('');
              const { _id, title } = values;
              // const description = editorRef.current.getContent();
              const image = tempImage;
              const data = {
                title,
                // description,
                image,
              };
              if (update) {
                try {
                  let res = await adminService.updatePage(_id, data, dispatch);
                  if (res && res.ok) {
                    setSuccessMsg(res.message || 'Page updated successfully.');
                  }
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message || 'Error updating Page. Please try again',
                  );
                }
              } else {
                try {
                  let res = await adminService.addPage(data, dispatch);
                  if (res && res.ok) {
                    setSuccessMsg(res.message || 'Page added successfully.');
                    resetForm({});
                  }
                  //
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message || 'Error adding Page. Please try again.',
                  );
                }
              }
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label="Title"
                      margin="normal"
                      size="medium"
                      name="title"
                      fullWidth
                      variant="outlined"
                      disabled={update ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {/* <Field
                      component={TextField}
                      label="Description"
                      margin="normal"
                      size="medium"
                      name="description"
                      fullWidth
                      variant="outlined"
                      multiline={true}
                      rows={5}
                    /> */}
                    {/**
                    
                    <Editor
                      editorState={editorState}
                      onEditorStateChange={(newState) => {
                        setEditorState(newState);
                      }}
                      initialValue={
                        update ? pageData && pageData.description : ''
                      }
                      apiKey="4ny0t7245hc05ozf21x1uks7oexltvjextcivy1ugr1yeyjh"
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          'image',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount',
                        ],
                        file_picker_types: 'image',
                        file_picker_callback: function (cb, value, meta) {
                          // eslint-disable-next-line no-undef
                          var editor = tinymce.activeEditor;
                          // create input element, call modal dialog w
                          var fileInput = document.createElement('input');
                          fileInput.setAttribute('type', 'file');
                          fileInput.setAttribute(
                            'accept',
                            'image/png, image/gif, image/jpeg, image/bmp, image/x-icon',
                          );
                          // if file is submitted run our key code
                          fileInput.addEventListener('change', () => {
                            if (
                              fileInput.files != null &&
                              fileInput.files[0] != null
                            ) {
                              // create instance of FileReader()
                              let reader = new FileReader();
                              // create event triggered after successful reading operation
                              reader.onload = (e) => {
                                // insert content in TinyMCE
                                uploadImageHandlerTinyMCE(fileInput.files[0])
                                  .then((res) => {
                                    editor.insertContent(
                                      '<img src="' + getImageUrl(res) + '">',
                                    );
                                    fileInput.value = '';
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                  });
                              };
                              reader.readAsDataURL(fileInput.files[0]);
                              console.log(reader);
                            }
                          });
                          fileInput.click();
                        },
                        toolbar:
                          'undo redo | formatselect |  fontsizeselect ' +
                          'bold italic backcolor | alignleft aligncenter ' +
                          'link image media |' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'removeformat | help',
                        content_style:
                          'https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap; body { font-family: Work Sans, sans-serif; }',
                      }}
                    />
                    
                    */}
                    <Typography component="p" className={classes.error}>
                      {contentError}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  Banner Image <br />
                  <input
                    type="file"
                    name="image"
                    onChange={(e) => uploadImageHandler(e)}
                    accept="image/*"
                  />
                  {values.image || values.image ? (
                    <Avatar
                      src={
                        update && !values.imagae
                          ? getImageUrl(values.image)
                          : getImageUrl(values.image)
                      }
                      variant="square"
                      className={classes.image}
                    />
                  ) : null}
                </Grid>
                <Box mt={1} mb={1}>
                  {uploadingError && (
                    <Alert
                      severity="error"
                      onClose={() => setUploadingError('')}
                    >
                      <AlertTitle>Error</AlertTitle>
                      {uploadingError}
                    </Alert>
                  )}
                  {errMsg && (
                    <Alert severity="error" onClose={() => setErrMsg('')}>
                      <AlertTitle>Error</AlertTitle>
                      {errMsg}
                    </Alert>
                  )}
                  {successMsg && (
                    <Alert severity="success" onClose={() => setSuccessMsg('')}>
                      <AlertTitle>Success</AlertTitle>
                      {successMsg}
                    </Alert>
                  )}
                </Box>
                {(isSubmitting || uploadingImage) && <LinearProgress />}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting || uploadingImage}
                    fullWidth
                    size="medium"
                    type="submit"
                    variant="contained"
                  >
                    {update ? 'Update Page' : 'Add Page'}
                  </Button>
                </Box>
                <FormPreviewDev data={values} />
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
}

export default EditAward;
