import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  // InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Field, Form, Formik, FieldArray } from 'formik';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import adminService from 'src/services/adminService';
import { consoleError, getImageUrl, uploadImage } from 'src/utils/helper';
import * as yup from 'yup';
import { TextField } from 'formik-material-ui';
import FormPreviewDev from 'src/components/common/FormPreviewDev';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useMatch, useNavigate } from 'react-router-dom';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { Editor } from '@tinymce/tinymce-react';
// import CustomFileUpload from 'src/components/CustomFileUpload';
import { Link } from 'react-router-dom';

import api from 'src/services/api';
import CustomFileUploadServer from 'src/components/CustomFileUploadServer';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.paper,
    // height: 'auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  banner: {
    height: '100%',
    width: '100%',
    maxWidth: '300px',
  },
  switchField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  blogCard: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
    fontSize: 15,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    marginLeft: theme.spacing(1),
  },
  demoWrapper: {
    width: '100%',
    display: 'block',
    marginBottom: '10px',
    height: '400px',
  },
  demoEditor: {
    height: '320px',
    border: '1px solid #F1F1F1',
    padding: '5px',
    borderRadius: '2px',
    fontFamily: 'Arial',
  },
}));
function IsJsonString(str) {
  try {
    var json = JSON.parse(str);
    return typeof json === 'object';
  } catch (e) {
    return false;
  }
}
function AddNews() {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [contentError, setContentError] = useState('');
  const [uploading, setUploading] = useState(false);
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty(),
  );
  const [uploadingImage, setUploadingImage] = useState(false);
  const [update, setUpdate] = useState(false);
  const [id, setId] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [image, setImage] = useState('');
  const [tempImage, setTempImage] = useState();
  const [slugVal, setSlugVal] = useState();
  const editorRef = useRef(null);

  const match = useMatch('/news/:id/edit');
  useEffect(() => {
    if (match && match.params && match.params.id) {
      setId(match.params.id);
      setUpdate(true);
    }
  }, [match]);

  const news = useSelector((state) => state.admin.news);
  const newsData = news.find((Item) => Item._id.toString() === id);
  useEffect(() => {
    getNews();
  }, []);

  const getNews = async () => {
    try {
      adminService.getNews(dispatch);
    } catch (error) {
      console.error(error);
    }
  };

  const addBlogSchema = yup.object().shape({
    title: yup
      .string()
      .required('Title is required.')
      .min(2, 'Title has min 2 chars.')
      .max(500, 'Title has max 500 chars.'),
    status: yup
      .string()
      .required('Status is required')
      .oneOf(['draft', 'published']),
  });
  const updateBlogSchema = yup.object().shape({
    title: yup
      .string()
      .required('Title is required.')
      .min(2, 'Title has min 2 chars.')
      .max(500, 'Title has max 500 chars.'),
    // description: yup
    //   .string()
    //   .required('Description is required.')
    //   .min(2, 'Description has min 2 chars.')
    //   .max(5000, 'Description has max 5000 chars.'),
    status: yup
      .string()
      .required('Status is required')
      .oneOf(['draft', 'published']),
  });

  const uploadImageHandler = async (e, values, setFieldValue) => {
    setUploading(true);
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    try {
      const res = await api.post(`/api/v1/admin/file/upload/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const image_New_Name = res.data;
      setTempImage(res.data);
      // values.image = image_New_Name;
      setImage(image_New_Name);
      console.log(values);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const uploadImageHandlerTinyMCE = async (file) => {
    console.log(file);
    const formData = new FormData();
    formData.append('image', file);
    try {
      const res = await api.post(`/api/v1/admin/file/upload/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (res && res.data) {
        return res.data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Page className={classes.root} title={update ? 'Update news' : 'Add news'}>
      <Box
        display="flex"
        flexdirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="lg">
          <Box my={2}>
            <Typography variant="h2">
              {update ? 'Update news' : 'Add news'}
            </Typography>
          </Box>
          <Box mt={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link color="gray" to="/">
                Dashboard
              </Link>
              <Link color="grey" to="/news">
                news
              </Link>
              <Typography color="textPrimary">
                {update ? 'Update' : 'Add'}
              </Typography>
            </Breadcrumbs>
          </Box>
          <Formik
            enableReinitialize={true}
            initialValues={
              update
                ? {
                    ...newsData,
                    thumbnailCurrentImage: newsData ? newsData.thumbnail : '',
                  }
                : {
                    title: '',
                    slug: '',
                    status: 'published',
                    thumbnail: '',
                    excerpt: '',
                    body: '',
                  }
            }
            validationSchema={update ? updateBlogSchema : addBlogSchema}
            onSubmit={async (values, { resetForm }) => {
              setErrMsg('');
              setSuccessMsg('');
              setContentError('');
              const { _id, title, slug, status, body, excerpt } = values;
              const thumbnail = tempImage;
              const data = {
                title,
                slug,
                status,
                body,
                excerpt,
                thumbnail,
              };
              if (update) {
                try {
                  let res = await adminService.updateNews(
                    _id,
                    {
                      title: data.title,
                      slug: data.slug,
                      excerpt: data.excerpt,
                      body: editorRef.current.getContent(),
                      status: data.status,
                      thumbnail: data.thumbnail,
                    },
                    dispatch,
                  );
                  if (res && res.ok) {
                    setSuccessMsg(res.message || 'News updated successfully.');
                  }
                  //
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message || 'Error updating News. Please try again.',
                  );
                }
              } else {
                try {
                  if (editorRef.current === null) {
                    setErrMsg('News Content is required');
                  } else {
                    let res = await adminService.addNews(
                      {
                        title: data.title,
                        slug: data.slug,
                        excerpt: data.excerpt,
                        body: editorRef.current.getContent(),
                        status: data.status,
                        thumbnail: data.thumbnail,
                      },
                      dispatch,
                    );
                    if (res && res.ok) {
                      setSuccessMsg(res.message || 'News added successfully.');
                      resetForm({});
                    }
                  }
                } catch (error) {
                  consoleError(error);
                  setErrMsg(
                    error.message || 'Error adding News. Please try again.',
                  );
                }
              }
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <Card className={classes.blogCard}>
                  <Grid
                    container
                    alignItems="flex-start"
                    justify="center"
                    spacing={2}
                  >
                    <Grid item md={12} xs={12}>
                      <Field
                        onChange={(e) => {
                          setFieldValue(
                            'slug',
                            `${e.target.value}`
                              .toLowerCase()
                              .split(' ')
                              .join('-')
                              .split(
                                // eslint-disable-next-line no-useless-escape
                                /[`'“”‘’!@#$%^&*()+\=\[\]{};:._'"\\|,<>\/?~]/,
                              )
                              .join(''),
                          );
                          setFieldValue('title', e.target.value);
                        }}
                        component={TextField}
                        label="Title"
                        margin="normal"
                        size="medium"
                        name="title"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Field
                        onChange={(e) => {
                          setFieldValue(
                            'slug',
                            e.target.value
                              .toLowerCase()
                              .split(' ')
                              .join('-')
                              .replace(
                                // eslint-disable-next-line no-useless-escape
                                /[`'“”‘’!@#$%^&*()+\=\[\]{};:._'"\\|,<>\/?~]/,
                                '',
                              ),
                          );
                        }}
                        component={TextField}
                        label="Slug"
                        margin="normal"
                        size="medium"
                        name="slug"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <input
                        type="file"
                        name="thumbnail"
                        onChange={(e) => uploadImageHandler(e)}
                        accept="image/*"
                      />
                      {uploading && <CircularProgress color="primary" />}
                      {values.thumbnail || values.thumbnailCurrentImage ? (
                        <Avatar
                          src={
                            update && !values.thumbnail
                              ? getImageUrl(values.thumbnailCurrentImage)
                              : getImageUrl(values.thumbnail)
                          }
                          variant="square"
                          className={classes.thumbnail}
                        />
                      ) : null}
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Field
                        component={TextField}
                        label="excerpt"
                        margin="normal"
                        size="medium"
                        name="excerpt"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Editor
                        editorState={editorState}
                        onEditorStateChange={(newState) => {
                          setEditorState(newState);
                        }}
                        initialValue={update ? newsData && newsData.body : ''}
                        apiKey="zestrjm70g1yah0594s3gfx6toqcat9du1kj4jmi03fezpw7"
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            'image',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                          ],
                          file_picker_types: 'image',
                          file_picker_callback: function (cb, value, meta) {
                            // eslint-disable-next-line no-undef
                            var editor = tinymce.activeEditor;
                            // create input element, call modal dialog w
                            var fileInput = document.createElement('input');
                            fileInput.setAttribute('type', 'file');
                            fileInput.setAttribute(
                              'accept',
                              'image/png, image/gif, image/jpeg, image/bmp, image/x-icon',
                            );
                            // if file is submitted run our key code
                            fileInput.addEventListener('change', () => {
                              if (
                                fileInput.files != null &&
                                fileInput.files[0] != null
                              ) {
                                // create instance of FileReader()
                                let reader = new FileReader();
                                // create event triggered after successful reading operation
                                reader.onload = (e) => {
                                  // insert content in TinyMCE
                                  uploadImageHandlerTinyMCE(fileInput.files[0])
                                    .then((res) => {
                                      editor.insertContent(
                                        '<img src="' + getImageUrl(res) + '">',
                                      );
                                      fileInput.value = '';
                                    })
                                    .catch((err) => {
                                      console.log(err);
                                    });
                                };
                                reader.readAsDataURL(fileInput.files[0]);
                                console.log(reader);
                              }
                            });
                            fileInput.click();
                          },
                          toolbar:
                            'undo redo | formatselect |  fontsizeselect ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'link image media |' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                          content_style:
                            'https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap; body { font-family: Work Sans, sans-serif; }',
                        }}
                      />
                      <Typography component="p" className={classes.error}>
                        {contentError}
                      </Typography>
                    </Grid>
                    <Box mt={2}></Box>
                    <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <Field
                          component={TextField}
                          name="status"
                          type="text"
                          label="Status"
                          select={true}
                          variant="outlined"
                          fullWidth
                        >
                          <MenuItem value={'draft'}>Draft</MenuItem>
                          <MenuItem value={'published'}>Published</MenuItem>
                        </Field>
                        {/* <ErrorMessage name="type" /> */}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Box mt={1} mb={1}>
                    {errMsg && (
                      <Alert severity="error" onClose={() => setErrMsg('')}>
                        <AlertTitle>Error</AlertTitle>
                        {errMsg}
                      </Alert>
                    )}
                    {successMsg && (
                      <Alert
                        severity="success"
                        onClose={() => setSuccessMsg('')}
                      >
                        <AlertTitle>Success</AlertTitle>
                        {successMsg}
                      </Alert>
                    )}
                  </Box>
                  {isSubmitting && <LinearProgress />}
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting || uploading}
                      fullWidth
                      size="medium"
                      type="submit"
                      variant="contained"
                    >
                      {update ? 'Update News' : 'Add News'}
                    </Button>
                  </Box>
                  <FormPreviewDev data={values} />
                </Card>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
}

export default AddNews;
