import React from 'react';
import config from 'src/config';

function FormPreviewDev({ data = {} }) {
  if (config.dev && typeof data == 'object') {
    return <pre>{JSON.stringify(data, null, 2)}</pre>;
  } else return null;
}

export default FormPreviewDev;
