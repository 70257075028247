import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import adminService from '../../services/adminService';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Button, Dialog, makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const pages = useSelector((state) => state.admin.pages);
  const pageData = pages.find((Item) => Item.slug === 'landing-page');
  console.log(pageData);
  useEffect(() => {
    async function getPages() {
      try {
        adminService.getPages(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getPages();
  }, []);

  const data = useSelector((state) => state.admin.contents);
  console.log(data);
  useEffect(() => {
    async function getContents() {
      try {
        adminService.getContents(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getContents();
  }, []);

  const contentData = data.filter(
    (content) => content.pageId === pageData?._id,
  );
  console.log(contentData);
  if (contentData.length > 0) {
    return null;
  } else {
    return (
      <div className={clsx(classes.root, className)} {...rest}>
        <Box display="flex" justifyContent="flex-start">
          <Button
            color="primary"
            variant="contained"
            onClick={() => navigate('/landing-page/content/add')}
          >
            Add Landing Page Content
          </Button>
        </Box>
      </div>
    );
  }
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
