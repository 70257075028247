import axios from 'axios';
import config from '../config';

const api = axios.create({
  baseURL: config.apiUrl,
});

api.defaults.headers.post['Content-Type'] = 'application/json';
// eslint-disable-next-line no-shadow
api.interceptors.request.use(function (config) {
  const accessToken = localStorage.getItem('accessToken');
  // if (accessToken) {
  //   api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  // }
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
});
api.interceptors.response.use(
  // eslint-disable-next-line consistent-return
  async (response) => {
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      if (data && data.user && data.user.role) {
        if (!['admin', 'superadmin'].includes(data.user.role)) {
          localStorage.removeItem('accessToken');
          return Promise.reject(
            new Error({
              status: 'error',
              message: "User doesn't have enough permission",
            }),
          );
        }
      }
      return Promise.resolve(response.data);
    }
  },
  async (error) => {
    if (error && error.message === 'Network Error') {
      window.location.href = '/500';
    }
    const { response, request } = error;
    if (response) {
      if (response.status === 401) {
        localStorage.removeItem('accessToken');
        window.location.href = '/login';
      }
      if (response.status >= 400 && response.status < 500) {
        return Promise.reject(response.data);
      }
    } else if (request) {
      return null;
    }
    return Promise.reject(error);
  },
);

export default api;
