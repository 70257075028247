import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Button, Dialog, makeStyles } from '@material-ui/core';
import { DialogContent, DialogTitle } from 'src/components/CustomDialog';
import AddUser from './AddUser';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();
  const [addModal, setAddModal] = useState(false);

  const openAddModal = () => {
    setAddModal(true);
  };
  const closeAddModal = () => {
    setAddModal(false);
  };
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-start">
        {/* <Button className={classes.importButton}>Import</Button>
        <Button className={classes.exportButton}>Export</Button> */}
        <Button color="primary" variant="contained" onClick={openAddModal}>
          Add User
        </Button>
      </Box>
      <Dialog open={addModal} onClose={closeAddModal}>
        <DialogTitle onClose={closeAddModal}>Add Service</DialogTitle>
        <DialogContent>
          <AddUser />
        </DialogContent>
      </Dialog>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
