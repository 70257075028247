import api from './api';

import {
  adminGetUsers,
  adminAddUser,
  adminUpdateUser,
  adminDeleteUser,
  adminGetSiteSetting,
  adminUpdateSiteSetting,
  adminGetPage,
  adminAddPage,
  adminUpdatePage,
  adminDeletePage,
  adminGetContents,
  adminAddContent,
  adminUpdateContent,
  adminDeleteContent,
  adminGetContactusall,
  adminDeleteContactus,
  adminGetNews,
  adminAddNews,
  adminUpdateNews,
  adminDeleteNews,
} from '../store/actions/adminActions';

const adminService = {
  // USER
  getUsers: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/users');
      dispatch(adminGetUsers(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addUser: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/admin/users', data, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
      dispatch(adminAddUser(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateUser: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/admin/users/${_id}`, data, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
      dispatch(adminUpdateUser(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteUser: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/users/${_id}`);
      dispatch(adminDeleteUser(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getTranslation: async (text) => {
    try {
      let res = await api.post('/api/v1/admin/translate/chinese', {
        text: text,
      });
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // Site Setting
  getSiteSetting: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/setting');
      dispatch(adminGetSiteSetting(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateSiteSetting: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/admin/setting/update/${_id}`, data);
      dispatch(adminUpdateSiteSetting(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // Page
  getPages: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/pages');
      dispatch(adminGetPage(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addPage: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/admin/page/store', data);
      dispatch(adminAddPage(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updatePage: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/admin/page/update/${_id}`, data);
      dispatch(adminUpdatePage(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deletePage: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/page/delete/${_id}`);
      dispatch(adminDeletePage(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // Page
  getContents: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/content');
      dispatch(adminGetContents(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addContent: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/admin/content/store', data);
      dispatch(adminAddContent(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateContent: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/admin/content/update/${_id}`, data);
      dispatch(adminUpdateContent(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteContent: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/content/delete/${_id}`);
      dispatch(adminDeleteContent(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  //Contact us
  getContactusall: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/contactusall');
      dispatch(adminGetContactusall(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteContactus: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/contactus/delete/${_id}`);
      dispatch(adminDeleteContactus(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // News
  getNews: async (dispatch) => {
    try {
      const res = await api.get('/api/v1/admin/news');
      dispatch(adminGetNews(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addNews: async (data, dispatch) => {
    try {
      const res = await api.post('/api/v1/admin/news/store', data);
      dispatch(adminAddNews(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateNews: async (_id, data, dispatch) => {
    try {
      const res = await api.patch(`/api/v1/admin/news/update/${_id}`, data);
      dispatch(adminUpdateNews(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteNews: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/admin/news/delete/${_id}`);
      dispatch(adminDeleteNews(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default adminService;
