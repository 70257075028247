import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import DashboardView from 'src/views/reports/DashboardView';

import UserListView from 'src/views/user/UserListView';

import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';

import SettingsView from 'src/views/settings/SettingsView';
import { isLoggedIn } from './utils/helper';

import Error500 from './views/errors/Error500';
import SiteSettingView from './views/siteSetting';
import SiteSetting from './views/siteSetting/SiteSetting';
import EditSiteSetting from './views/siteSetting/EditSiteSetting';
import ContactView from './views/contact/ContactView';
import Contact from './views/contact/ContactView/Contact';
import NewsListView from './views/news';
import AddNews from './views/news/AddNews';
import PagesView from './views/pages';
import Page from './components/Page';
import EditPage from './views/pages/EditPage';
import ContentView from './views/content';
import Content from './views/content/Content';
import EditContent from './views/content/EditContent';
import AboutPageView from './views/about';
import TrackPageView from './views/track';
import TermsPageView from './views/terms';
import IndexContentPageView from './views/index-page-content';
import EditIndexPageContent from './views/index-page-content/EditContent';

export const getRoutes = (pathname) => {
  const routes = [
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: 'login',
          element: isLoggedIn() ? <Navigate to="/" /> : <LoginView />,
        },
        { path: '404', element: <NotFoundView /> },
        { path: '500', element: <Error500 /> },
        {
          path: '/',
          element: isLoggedIn() ? (
            <DashboardLayout />
          ) : (
            <Navigate to="/login" state={{ pathname }} />
          ),
          children: [
            { path: '/', element: <Navigate to="/dashboard" /> },
            { path: 'account', element: <AccountView /> },
            { path: 'users', element: <UserListView /> },
            { path: 'dashboard', element: <DashboardView /> },
            { path: 'settings', element: <SettingsView /> },
            { path: '*', element: <NotFoundView /> },
            {
              path: 'site-setting',
              children: [
                {
                  path: '/',
                  element: <SiteSettingView />,
                },
                {
                  path: '/view/:settingId',
                  element: <SiteSetting />,
                },
                {
                  path: '/edit/:settingId',
                  element: <EditSiteSetting />,
                },
                {
                  path: '/add',
                  element: <EditSiteSetting />,
                },
              ],
            },
            {
              path: 'contactus',
              children: [
                {
                  path: '/',
                  element: <ContactView />,
                },
                {
                  path: ':contactId',
                  element: <Contact />,
                },
              ],
            },
            {
              path: 'content',
              children: [
                {
                  path: ':contentId',
                  element: <Content />,
                },
                {
                  path: '/:contentId/edit',
                  element: <EditContent />,
                },
                {
                  path: '/add',
                  element: <EditContent />,
                },
              ],
            },
            {
              path: 'landing-page',
              children: [
                {
                  path: '/',
                  element: <PagesView />,
                },
                {
                  path: ':pageId',
                  element: <Page />,
                },
                {
                  path: '/:pageId/edit',
                  element: <EditPage />,
                },
                {
                  path: '/add',
                  element: <EditPage />,
                },
                {
                  path: '/content',
                  element: <IndexContentPageView />,
                },
                {
                  path: '/content/add',
                  element: <EditIndexPageContent />,
                },
                {
                  path: '/content/:contentId/edit',
                  element: <EditIndexPageContent />,
                },
              ],
            },
            {
              path: 'about-us',
              children: [
                {
                  path: '/',
                  element: <AboutPageView />,
                },
              ],
            },
            {
              path: 'track',
              children: [
                {
                  path: '/',
                  element: <TrackPageView />,
                },
              ],
            },
            {
              path: 'about-us/content',
              children: [
                {
                  path: '/',
                  element: <ContentView />,
                },
              ],
            },
            {
              path: 'terms-of-use',
              children: [
                {
                  path: '/',
                  element: <TermsPageView />,
                },
              ],
            },
            {
              path: 'news',
              children: [
                {
                  path: '/',
                  element: <NewsListView />,
                },
                {
                  path: '/add',
                  element: <AddNews />,
                },
                {
                  path: '/:newsId/edit',
                  element: <AddNews />,
                },
              ],
            },
          ],
        },
        { path: '*', element: <NotFoundView /> },
      ],
    },
  ];
  return routes;
};

// export default routes;
