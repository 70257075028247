export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export const CHANGE_AVATAR = 'CHANGE_AVATAR';

export function loginUser(payload) {
  return { type: LOGIN_USER, payload };
}
export function logoutUser() {
  return { type: LOGOUT_USER };
}
export function getProfile(payload) {
  console.log('payload => ', payload);
  return { type: GET_PROFILE, payload };
}
export function updateProfile(payload) {
  return { type: UPDATE_PROFILE, payload };
}
export function changeAvatar(payload) {
  return { type: CHANGE_AVATAR, payload };
}
