import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Avatar,
  Box,
  Card,
  Typography,
  makeStyles,
  Dialog,
  DialogContent,
  Button,
  Snackbar,
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import { consoleError, getImageUrl } from 'src/utils/helper';
import MaterialTable from 'material-table';
import { tableIcons } from 'src/components/common/TableIcons';
import { Edit as EditIcon, Trash2 as DeleteIcon } from 'react-feather';
import adminService from 'src/services/adminService';
import { useDispatch, useSelector } from 'react-redux';
import { DialogActions, DialogTitle } from 'src/components/CustomDialog';
import { Alert } from '@material-ui/lab';
// import AddBlog from './AddBlog';
import { useNavigate } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const NewsTable = ({ className, ...rest }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();

  const news = useSelector((state) => state.admin.news);
  useEffect(() => {
    async function getNews() {
      try {
        adminService.getNews(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getNews();
  }, []);

  // const [updateModal, setUpdateModal] = useState(false);

  // const [updateBlog, setUpdateBlog] = useState(null);

  // const openUpdateModal = () => {
  //   setUpdateModal(true);
  // };
  // const closeUpdateModal = () => {
  //   setUpdateModal(false);
  // };
  const [deleteModal, setDeleteModal] = useState(false);

  const [deleteBlog, setDeleteBlog] = useState(null);

  const openDeleteModal = () => {
    setDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
    setDeleteBlog(null);
  };

  const handleDeleteBlog = async () => {
    try {
      await adminService.deleteNews(deleteBlog._id, dispatch);
      closeDeleteModal();
      setDeleteSuccess(true);
    } catch (error) {
      consoleError(error);
      closeDeleteModal();
      setDeleteError(true);
    }
  };

  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteError, setDeleteError] = useState(false);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Box maxWidth={'95%'}>
        <MaterialTable
          icons={tableIcons}
          columns={[
            {
              title: 'Title',
              field: 'title',
              // eslint-disable-next-line react/display-name
              render: (rowData) => (
                <Box alignItems="center" display="flex">
                  <Typography color="textPrimary" variant="body1">
                    {rowData?.title}
                  </Typography>
                </Box>
              ),
            },
            { title: 'Status', field: 'status' },
            { title: 'Slug', field: 'slug' },
            {
              title: 'Created At',
              field: 'createdAt',
              render: (rowData) =>
                moment(rowData.createdAt).format('DD/MM/YYYY'),
            },
            {
              title: 'Updated At',
              field: 'updatedAt',
              render: (rowData) =>
                moment(rowData.updatedAt).format('DD/MM/YYYY'),
            },
          ]}
          data={news}
          // onRowClick={(event, rowData) => navigate(`./${rowData._id}`)}
          title="News"
          actions={[
            {
              icon: EditIcon,
              tooltip: 'Edit news',
              onClick: (event, rowData) => {
                event.stopPropagation();
                // setUpdatenews(rowData);
                // openUpdateModal();
                navigate(`/news/${rowData._id}/edit`);
              },
            },
            {
              icon: DeleteIcon,
              tooltip: 'Delete news',
              onClick: (event, rowData) => {
                event.stopPropagation();
                setDeleteBlog(rowData);
                openDeleteModal();
              },
            },
          ]}
          options={{
            rowStyle: {
              fontSize: '1rem',
              fontWeight: 'lighter',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serifs',
            },
            actionsColumnIndex: -1,
          }}
        />
      </Box>
      {/* <Dialog open={updateModal} onClose={closeUpdateModal}>
        <DialogTitle onClose={closeUpdateModal}>Update Blog</DialogTitle>
        <DialogContent>
          <AddBlog blog={updateBlog} />
        </DialogContent>
      </Dialog> */}
      <Dialog open={deleteModal} onClose={closeDeleteModal}>
        <DialogTitle onClose={closeDeleteModal}>Delete news</DialogTitle>
        <DialogContent>
          <Box
            height="50px"
            display="flex"
            alignItems="center"
            flexdirection="column"
          >
            <Typography variant="h6">
              Do you really want to delete news?
            </Typography>
            <Typography variant="h6">
              &quot;
              <small>
                <i>{deleteBlog?.title}</i>
              </small>
              &quot;
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteBlog} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={deleteSuccess}
        autoHideDuration={3000}
        onClose={() => setDeleteSuccess(false)}
      >
        <Alert onClose={() => setDeleteSuccess(false)} severity="success">
          news deleted successfully.
        </Alert>
      </Snackbar>

      <Snackbar
        open={deleteError}
        autoHideDuration={3000}
        onClose={() => setDeleteError(false)}
      >
        <Alert onClose={() => setDeleteError(false)} severity="error">
          Error deleting news.
        </Alert>
      </Snackbar>
    </Card>
  );
};

NewsTable.propTypes = {
  className: PropTypes.string,
};

export default NewsTable;
