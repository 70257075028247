import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const CustomFileUpload = (props) => (
  <FormControl>
    {props.label && (
      <InputLabel
        shrink
        style={{ transform: 'scale(1)' }}
        error={!!props.form.errors[props.field.name]}
      >
        {props.label}
      </InputLabel>
    )}
    <Input
      error={!!props.form.error}
      inputProps={{
        type: 'file',
        disabled: props.disabled || props.form.isSubmitting,
        name: props.field.name,
        onChange: (event) => {
          const file = event.currentTarget.files[0];
          props.form.setFieldValue(props.field.name, file ? file : '');
        },
      }}
    />
    {props.form.errors[props.field.name] && (
      <FormHelperText error>
        {props.form.errors[props.field.name]}
      </FormHelperText>
    )}
  </FormControl>
);
CustomFileUpload.propTypes = {
  label: PropTypes.string,
  form: PropTypes.any,
  field: PropTypes.any,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};
export default CustomFileUpload;
