// USERS
export const ADMIN_GET_USERS = 'ADMIN_GET_USERS';
export const ADMIN_ADD_USER = 'ADMIN_ADD_USER';
export const ADMIN_UPDATE_USER = 'ADMIN_UPDATE_USER';
export const ADMIN_DELETE_USER = 'ADMIN_DELETE_USER';

export function adminGetUsers(payload) {
  return { type: ADMIN_GET_USERS, payload };
}
export function adminAddUser(payload) {
  return { type: ADMIN_ADD_USER, payload };
}
export function adminUpdateUser(payload) {
  return { type: ADMIN_UPDATE_USER, payload };
}
export function adminDeleteUser(payload) {
  return { type: ADMIN_DELETE_USER, payload };
}

// SITE SETTING
export const ADMIN_GET_SITE_SETTING = 'ADMIN_GET_SITE_SETTING';
export const ADMIN_UPDATE_SITE_SETTING = 'ADMIN_UPDATE_SITE_SETTING';

export function adminGetSiteSetting(payload) {
  return { type: ADMIN_GET_SITE_SETTING, payload };
}
export function adminUpdateSiteSetting(payload) {
  return { type: ADMIN_UPDATE_SITE_SETTING, payload };
}

// PAGES
export const ADMIN_GET_PAGE = 'ADMIN_GET_PAGE';
export const ADMIN_ADD_PAGE = 'ADMIN_ADD_PAGE';
export const ADMIN_UPDATE_PAGE = 'ADMIN_UPDATE_PAGE';
export const ADMIN_DELETE_PAGE = 'ADMIN_DELETE_PAGE';

export function adminGetPage(payload) {
  return { type: ADMIN_GET_PAGE, payload };
}
export function adminAddPage(payload) {
  return { type: ADMIN_ADD_PAGE, payload };
}
export function adminUpdatePage(payload) {
  return { type: ADMIN_UPDATE_PAGE, payload };
}
export function adminDeletePage(payload) {
  return { type: ADMIN_DELETE_PAGE, payload };
}

// CONTENTS
export const ADMIN_GET_CONTENT = 'ADMIN_GET_CONTENT';
export const ADMIN_ADD_CONTENT = 'ADMIN_ADD_CONTENT';
export const ADMIN_UPDATE_CONTENT = 'ADMIN_UPDATE_CONTENT';
export const ADMIN_DELETE_CONTENT = 'ADMIN_DELETE_CONTENT';

export function adminGetContents(payload) {
  return { type: ADMIN_GET_CONTENT, payload };
}
export function adminAddContent(payload) {
  return { type: ADMIN_ADD_CONTENT, payload };
}
export function adminUpdateContent(payload) {
  return { type: ADMIN_UPDATE_CONTENT, payload };
}
export function adminDeleteContent(payload) {
  return { type: ADMIN_DELETE_CONTENT, payload };
}

//Contact us
export const ADMIN_GET_CONTACTUSALL = 'ADMIN_GET_CONTACTUSALL';
export const ADMIN_DELETE_CONTACTUS = 'ADMIN_DELETE_CONTACTUS';

export function adminGetContactusall(payload) {
  return { type: ADMIN_GET_CONTACTUSALL, payload };
}
export function adminDeleteContactus(payload) {
  return { type: ADMIN_DELETE_CONTACTUS, payload };
}

// NEWS
export const ADMIN_GET_NEWS = 'ADMIN_GET_NEWS';
export const ADMIN_ADD_NEWS = 'ADMIN_ADD_NEWS';
export const ADMIN_UPDATE_NEWS = 'ADMIN_UPDATE_NEWS';
export const ADMIN_DELETE_NEWS = 'ADMIN_DELETE_NEWS';

export function adminGetNews(payload) {
  return { type: ADMIN_GET_NEWS, payload };
}
export function adminAddNews(payload) {
  return { type: ADMIN_ADD_NEWS, payload };
}
export function adminUpdateNews(payload) {
  return { type: ADMIN_UPDATE_NEWS, payload };
}
export function adminDeleteNews(payload) {
  return { type: ADMIN_DELETE_NEWS, payload };
}
