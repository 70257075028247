import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Avatar,
  Box,
  Card,
  Typography,
  makeStyles,
  Dialog,
  DialogContent,
  Button,
  Snackbar,
} from '@material-ui/core';
import { consoleError, getImageUrl } from '../../utils/helper';
import MaterialTable from 'material-table';
import { tableIcons } from '../../components/common/TableIcons';
import { Edit as EditIcon, Trash2 as DeleteIcon } from 'react-feather';
import adminService from '../../services/adminService';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DialogActions, DialogTitle } from '../../components/CustomDialog';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const ContentsTable = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector((state) => state.admin.contents);
  console.log(data);
  useEffect(() => {
    async function getContents() {
      try {
        adminService.getContents(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getContents();
  }, []);

  const pages = useSelector((state) => state.admin.pages);
  const pageData = pages.find((Item) => Item.slug === 'landing-page');
  const contentData = data.filter(
    (content) => content.pageId === pageData?._id,
  );
  console.log(pageData);
  useEffect(() => {
    async function getPages() {
      try {
        adminService.getPages(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getPages();
  }, []);

  const [deleteModal, setDeleteModal] = useState(false);

  const [deleteCategory, setDeleteCategory] = useState(null);

  const openDeleteModal = () => {
    setDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
    setDeleteCategory(null);
  };

  const handleDeleteUser = async () => {
    try {
      await adminService.deleteContent(deleteCategory._id, dispatch);
      closeDeleteModal();
      setDeleteSuccess(true);
    } catch (error) {
      consoleError(error);
      closeDeleteModal();
      setDeleteError(true);
    }
  };

  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteError, setDeleteError] = useState(false);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Box maxWidth={'95%'}>
        <MaterialTable
          icons={tableIcons}
          columns={[
            {
              title: 'Title',
              field: 'title',
              filtering: false,
              cellStyle: {
                textTransform: 'capitalize',
              },
            },
            { title: 'Description', field: 'description', filtering: false },
          ]}
          data={contentData}
          // onRowClick={(event, rowData) => navigate(`./${rowData._id}`)}
          title="Contents"
          actions={[
            {
              icon: EditIcon,
              tooltip: 'Edit Content',
              onClick: (event, rowData) => {
                event.stopPropagation();
                navigate(`/landing-page/content/${rowData._id}/edit`);
              },
            },
            {
              icon: DeleteIcon,
              tooltip: 'Delete Content',
              onClick: (event, rowData) => {
                event.stopPropagation();
                setDeleteCategory(rowData);
                openDeleteModal();
              },
            },
          ]}
          options={{
            rowStyle: {
              fontSize: '1rem',
              fontWeight: 'lighter',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serifs',
            },
            actionsColumnIndex: -1,
            filtering: true,
            pageSize: 6,
          }}
        />
      </Box>
      <Dialog open={deleteModal} onClose={closeDeleteModal}>
        <DialogTitle onClose={closeDeleteModal}>Delete Content</DialogTitle>
        <DialogContent>
          <Box
            height="50px"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="h6">
              Do you really want to delete content?
            </Typography>
            <Typography variant="h6">
              &quot;
              <small>
                <i>{deleteCategory ? deleteCategory.title : ''}</i>
              </small>
              &quot;
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteUser} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={deleteSuccess}
        autoHideDuration={3000}
        onClose={() => setDeleteSuccess(false)}
      >
        <Alert onClose={() => setDeleteSuccess(false)} severity="success">
          contents deleted successfully.
        </Alert>
      </Snackbar>

      <Snackbar
        open={deleteError}
        autoHideDuration={3000}
        onClose={() => setDeleteError(false)}
      >
        <Alert onClose={() => setDeleteError(false)} severity="error">
          Error deleting contents.
        </Alert>
      </Snackbar>
    </Card>
  );
};

ContentsTable.propTypes = {
  className: PropTypes.string,
};

export default ContentsTable;
