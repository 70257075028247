import { combineReducers } from 'redux';

import userReducer from './userReducer';
import adminReducer from './adminReducer';

const appReducer = combineReducers({
  user: userReducer,
  admin: adminReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER') {
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;
