import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  Settings as SettingsIcon,
  User as UserIcon,
  Users as UsersIcon,
} from 'react-feather';
import NavItem from './NavItem';
import InputIcon from '@material-ui/icons/Input';

import userService from 'src/services/userService';
import { useDispatch, useSelector } from 'react-redux';
import { getImageUrl, isLoggedIn } from 'src/utils/helper';
import Navigation from 'src/components/Navigation';

const items = [
  {
    title: 'Main',
    pages: [
      {
        href: '/dashboard',
        icon: BarChartIcon,
        title: 'Dashboard',
      },
      {
        href: '/account',
        icon: UserIcon,
        title: 'Account',
      },
      {
        href: '/site-setting',
        icon: SettingsIcon,
        title: 'Site Setting',
      },
      {
        href: '/contactus',
        icon: UsersIcon,
        title: 'Enquiries',
      },
      {
        href: '/landing-page',
        icon: UsersIcon,
        title: 'Landing Page',
      },
      {
        href: '/landing-page/content',
        icon: UsersIcon,
        title: 'Landing Page Content',
      },
      {
        href: '/about-us',
        icon: UsersIcon,
        title: 'About Us Page',
      },
      {
        href: '/about-us/content',
        icon: UsersIcon,
        title: 'About Us Page Content',
      },
      {
        href: '/track',
        icon: UsersIcon,
        title: 'Track Page',
      },
      {
        href: '/terms-of-use',
        icon: UsersIcon,
        title: 'Terms of Use',
      },
      {
        href: '/news',
        icon: UsersIcon,
        title: 'News',
      },

      // {
      //   href: '/settings',
      //   icon: SettingsIcon,
      //   title: 'Settings',
      // },
    ],
  },
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.profile);
  console.log('user => ', user);
  useEffect(() => {
    async function getUser() {
      try {
        await userService.profile(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    if (isLoggedIn() && !user?.name) {
      getUser();
    }
  }, []);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={getImageUrl(user.avatar)}
          to="/account"
        />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {console.log('user 149 => ', user)}
          {user.name === ''
            ? async () => await userService.profile(dispatch)
            : user[0].role === 'staff'
            ? user[1].name
            : user[0].name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user.name === ''
            ? async () => await userService.profile(dispatch)
            : user[0].role === 'staff'
            ? user[1].role
            : user[0].role}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        {/* <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List> */}
        <nav>
          {items.map((list) => (
            <Navigation
              component="div"
              key={list.title}
              pages={list.pages}
              title={list.title}
            />
          ))}
        </nav>
      </Box>
      <Box flexGrow={1} />
      <Hidden lgUp>
        <Box p={2}>
          <List
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              userService.logout(dispatch);
            }}
          >
            <NavItem href="/logout" title="Logout" icon={InputIcon} />
          </List>
        </Box>
      </Hidden>
    </Box>
  );

  return (
    <>
      <Hidden mdUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
