import React, { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Box,
  Container,
} from '@material-ui/core';
import Page from 'src/components/Page';
import axios from 'axios';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  imageContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto',
  },
  buttonContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const Error500 = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  useEffect(() => {
    async function getServerStatus() {
      let response = await axios.get('/api/v1/status', {
        baseURL: config.apiUrl,
      });
      const res = response.data;
      if (res && res.ok) {
        console.log(res.message || 'Server is fine. Redirect to home');
        navigate(-1);
        clearInterval(status);
      }
    }
    let status = setInterval(() => {
      getServerStatus();
    }, 5000);
  }, [history]);

  return (
    <Page className={classes.root} title="Error 500">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Typography align="center" variant={mobileDevice ? 'h4' : 'h1'}>
            500: Ooops, something went terribly wrong!
          </Typography>
          <Typography align="center" variant="subtitle2">
            You either tried some shady move or you came here by mistake.
            Whichever it is, try using the navigation
          </Typography>
          <div className={classes.imageContainer}>
            <img
              alt="Under development"
              className={classes.image}
              src="/static/images/undraw_server_down_s4lk.svg"
            />
          </div>
          <div className={classes.buttonContainer}>
            <Button
              color="primary"
              component={RouterLink}
              to="/"
              variant="outlined"
            >
              Back to home
            </Button>
          </div>
        </Container>
      </Box>
    </Page>
  );
};

export default Error500;
