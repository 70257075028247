import React, { useEffect, useState } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
// import TotalTechnicians from './TotalTechnicians';
// import LatestOrders from './LatestOrders';
// import LatestProducts from './LatestProducts';
// import Sales from './Sales';
// import TasksProgress from './TasksProgress';
import TotalCustomers from './TotalCustomers';
// import TotalProfit from './TotalProfit';
// import TrafficByDevice from './TrafficByDevice';
import api from 'src/services/api';
import { consoleError } from 'src/utils/helper';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Dashboard = () => {
  const classes = useStyles();

  const [users, setUsers] = useState({
    customers: '-',
    technicians: '-',
  });

  useEffect(() => {
    const getUsersByRole = async () => {
      try {
        const res = await api.get('/api/v1/admin/reports/users/role');
        if (res && res.ok) {
          const data = res.data;
          setUsers({
            customers: data?.user || '-',
            technicians: data?.technician || '-',
          });
        }
      } catch (error) {
        consoleError(error);
      }
    };
    getUsersByRole();
  }, []);

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalCustomers data={users.customers} />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            {/* <TotalTechnicians data={users.technicians} /> */}
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            {/* <TasksProgress /> */}
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            {/* <TotalProfit /> */}
          </Grid>
          {/* <Grid item lg={8} md={12} xl={9} xs={12}>
            <Sales />
          </Grid>
          <Grid item lg={4} md={6} xl={3} xs={12}>
            <TrafficByDevice />
          </Grid>
          <Grid item lg={4} md={6} xl={3} xs={12}>
            <LatestProducts />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
