import React, { useEffect } from 'react';
import Page from '../../components/Page';
import { Link } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  //   CardHeader,
  Container,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Breadcrumbs,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import adminService from '../../services/adminService';
import { useParams } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  mb3: {
    marginBottom: '15px',
  },
  favicon: {
    maxHeight: '100px',
  },
  logo: {
    maxHeight: '200px',
  },
}));

function SiteSetting() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { settingId } = useParams();
  // console.log(settingId);

  const siteSettings = useSelector((state) => state.admin.siteSettings);
  // console.log(siteSettings);
  const data = siteSettings.find((Item) => Item._id.toString() === settingId);
  // console.log(data);
  useEffect(() => {
    async function getSettings() {
      try {
        adminService.getSiteSetting(dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    getSettings();
  }, []);
  return (
    <Page className={classes.root} title="Ledger">
      <Container maxWidth={false}>
        <Box mt={3}>
          <Typography color="textPrimary" gutterBottom variant="h2">
            Site Setting
          </Typography>
          <Box mt={2}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link color="gray" to="/">
                Dashboard
              </Link>
              <Link color="grey" to="/site-setting">
                Site Setting
              </Link>
              <Typography color="textPrimary">
                {data ? data._id : ''}
              </Typography>
            </Breadcrumbs>
          </Box>
          {data ? (
            <Box>
              <Card>
                <Box display="flex" ml={2} mt={2} alignItems="center">
                  <Typography color="textPrimary" variant="h4">
                    Details{' '}
                  </Typography>
                </Box>
                <CardContent>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Id
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {data._id}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Title
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {data.title}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Phone
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {data.phone}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Email
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {data.email}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Business Email
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {data.businessEmail}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Facebook
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {data.facebook}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Instagram
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {data.instagram}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            messenger
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {data.messenger}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            whatsapp
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {data.whatsapp}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            business hours
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {data.businesshours}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Address
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            {data.address}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            FavIcon
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <img
                              src={data.favicon}
                              alt="Service"
                              className={classes.favicon}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                          >
                            Logo
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <img
                              src={data.logo}
                              alt="Service"
                              className={classes.logo}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Box>
          ) : (
            <Box mt={5} display="flex" justifyContent="center">
              <Typography
                align="center"
                color="textSecondary"
                gutterBottom
                variant="h3"
              >
                site setting Not Available
              </Typography>
            </Box>
          )}
        </Box>
      </Container>
    </Page>
  );
}
export default SiteSetting;
